<template>
  <b-card :title="`Código: ${dadoCliente.id}` ">

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="8">
          <b-form-group
            label="Nome"
            label-for="nome"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.nome"
                name="nome"
                placeholder="Nome"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.nome"
                name="nome"
                placeholder="nome"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="CPF/CNPJ"
            label-for="cpf-cnpj"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.cpfCnpj"
                name="cpf-cnpj"
                placeholder="CPF/CNPJ"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.cpfCnpj"
                name="cpf-cnpj"
                placeholder="CPF/CNPJ"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col
          v-if="!editar"
          sm="4"
        >
          <b-form-group
            label="CEP"
            label-for="cep"
          >

            <b-form-input
              v-model="dadoCliente.cep"
              name="cep"
              placeholder="CEP"
              readonly
            />

          </b-form-group>

        </b-col>
        <b-col
          v-else
          sm="4"
        >
          <label>CEP</label>
          <b-input-group>
            <b-form-input
              v-model="dadoCliente.cep"
              name="cep"
              placeholder="CEP"
              @keyup.enter="consultaCEP"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="consultaCEP"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col sm="8">
          <b-form-group
            label="Rua"
            label-for="Rua"
          >
            <b-form-input
              v-model="dadoCliente.rua"
              name="rua"
              placeholder="Rua"
              readonly
            />
          </b-form-group>

        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Número"
            label-for="numero"
          >
            <div v-if="editar">
              <b-form-input
                v-model="dadoCliente.numero"
                name="numero"
                placeholder="numero"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="dadoCliente.numero"
                name="numero"
                placeholder="Número"
                readonly
              />
            </div>
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Bairro"
            label-for="bairro"
          >
            <b-form-input
              v-model="dadoCliente.bairro"
              name="bairro"
              placeholder="Bairro"
              readonly
            />
          </b-form-group>

        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Cidade"
            label-for="cidade"
          >
            <b-form-input
              v-model="dadoCliente.cidade"
              name="cidade"
              placeholder="Cidade"
              readonly
            />
          </b-form-group>

        </b-col>
        <b-col sm="2">
          <b-form-group
            label="UF"
            label-for="uf"
          >
            <b-form-input
              v-model="dadoCliente.uf"
              name="uf"
              placeholder="UF"
              readonly
            />

          </b-form-group>

        </b-col>

        <b-col
          v-if="permissions['clientes.update'].permissao"
          cols="12"
        >
          <div v-if="!editar">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="btEditarCliente"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">Editar</span>
            </b-button>
          </div>
          <div v-else>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="updateCliente"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Salvar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Resetar
            </b-button>
          </div>

        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from '@/../axios-auth'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      permissions: null,
      editar: false,
      dadoCliente: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  created() {
    this.permissions = JSON.parse(localStorage.permissoes).clientes
  },
  methods: {
    resetForm() {
      this.dadoCliente = JSON.parse(JSON.stringify(this.generalData))
      this.editar = false
    },
    updateCliente() {
      const formData = {
        nome: this.dadoCliente.nome,
        cep: this.dadoCliente.cep,
        endereco: `${this.dadoCliente.rua}`,
        bairro: this.dadoCliente.bairro,
        cpf_cnpj: this.dadoCliente.cpfCnpj,
        numero: this.dadoCliente.numero,
        cidades_id: this.dadoCliente.cidades_id,
        cidade: this.dadoCliente.cidade,
      }
      axios.put(`api/v1/clientes/update/${this.dadoCliente.id}`, formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.editar = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${res.data.mensagem}`,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
    },
    consultaCEP() {
      const cep = this.dadoCliente.cep.replace(/[^0-9]/g, '')
      axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => {
          this.dadoCliente.numero = ''
          this.dadoCliente.rua = res.data.logradouro
          this.dadoCliente.bairro = res.data.bairro
          this.dadoCliente.cidade = res.data.localidade
          this.dadoCliente.uf = res.data.uf
        })
    },
    btEditarCliente() {
      this.editar = true
    },
  },
}
</script>
