<template>
  <div>
    <b-row
      align-h="end"
      class="mb-2"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$refs.modalEvento.show()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          Evento
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      ref="modalEvento"
      title="Evento avulso"
      no-close-on-backdrop
      ok-title="Salvar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @show="clearModal"
      @ok="storeEvento"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          Motivo evento:
          <v-select
            v-model="motEventos.select"
            :options="motEventos.options"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Descrição:
          <b-form-textarea
            v-model="descricao"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card title="Eventos">
        <b-row>
          <b-col>
            <b-row align-h="between">
              <b-col cols="1">
                <b-form-select
                  v-model="perPage"
                  :options="[10, 15, 20]"
                  size="sm"
                />
              </b-col>
              <b-col
                lg="8"
                sm="12"
              >
                <b-form-input
                  v-model="filter"
                  type="search"
                  size="sm"
                  placeholder="Pesquisar"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-table
              responsive
              :fields="fields"
              :items="eventos"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <template #cell(descricao)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vc-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">{{ row.detailsShowing ? 'Esconder' : 'Descrição' }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col
                      md="12"
                      class="mb-1"
                    >
                      {{ row.item.descricao }}
                    </b-col>
                  </b-row>

                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Esconder Descrição
                  </b-button>
                </b-card>
              </template>
              <template #cell(created_at)="data">
                {{ dataHora(data.item.created_at) }}
              </template>
              <template #cell(usuario)="data">
                <b-media>
                  <template #aside>
                    <b-avatar
                      size="32"
                      :text="getInitials(data.item.usuario_empresas.user.name)"
                    />
                  </template>
                  {{ data.item.usuario_empresas.user.name }}
                </b-media>
              </template>
            </b-table>
          </b-col>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              align="center"
              size="sm"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BButton,
  BFormCheckbox,
  BTable,
  BCard,
  BRow,
  BCol,
  BPagination,
  BFormTextarea,
  BMedia,
  BAvatar,
  BFormSelect,
  BFormInput,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BButton,
    BFormCheckbox,
    BTable,
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormTextarea,
    BMedia,
    BAvatar,
    BFormSelect,
    BFormInput,
    BModal,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      descricao: '',
      load: true,

      eventos: [],
      permissions: null,
      filter: '',
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'descricao', label: 'descrição' },
        { key: 'created_at', label: 'data e hora', sortable: true },
        { key: 'user', label: 'criador' },
        { key: 'evento', sortable: true },
      ],
      motEventos: {
        select: {
          id: '',
          label: '',
        },
        options: [],
      },
    }
  },
  async created() {
    this.permissions = JSON.parse(localStorage.permissoes)

    this.getEventos()

    this.getMotEventos()
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearModal() {
      this.descricao = ''
      this.motEventos.select = {
        id: '',
        label: '',
      }
    },
    async getEventos() {
      this.eventos = []
      this.load = true
      await axios.get(`api/v1/clientes/eventos/${this.$route.params.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.eventos.unshift({
            user: dt.usuario_empresas.user.name,
            descricao: dt.descricao,
            created_at: dt.created_at,
            evento: dt.motivo_evento.nome,
          })
        })
        this.load = false
        this.totalRows = this.eventos.length
      })
    },
    async getMotEventos() {
      await axios.get('api/v1/motivo_evento', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.motEventos.options.push({
            id: dt.id,
            label: dt.nome,
          })
        })
      })
    },

    storeEvento() {
      const body = {
        descricao: this.descricao,
        cliente_id: parseInt(this.$route.params.id),
        motivo_evento_id: this.motEventos.select.id,
      }
      axios.post('api/v1/eventos/store', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(async () => {
        await this.getEventos()
        await this.$swal({
          title: 'Evento adicionado',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
    },
  },
}

</script>
