<template>
  <div>
    <b-button
      class="mb-1"
      variant="flat-primary"
      @click="$router.go(-1)"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Voltar</span>
    </b-button>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >

      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Informações Gerais</span>
        </template>

        <ClienteGeral
          v-if="loadCliente"
          :general-data="cliente"
        />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="SendIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Email</span>
        </template>

        <ClienteEmail
          v-if="loadCliente"
          :email-data="emails"
          :cliente-id="clienteId"
        />

      </b-tab>
      <!--/ change password tab -->

      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="PhoneCallIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Telefone</span>
        </template>

        <ClienteTelefones
          v-if="telefones"
          :telefones="telefones"
          :cliente-id="clienteId"
        />

      </b-tab>

      <!-- social links -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Eventos</span>
        </template>

        <ClienteEventos />

      </b-tab>

      <!-- notification -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LayersIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Operações</span>
        </template>

        <ClienteOperacao
          v-if="operacoes"
          :operacoes="operacoes"
          :info="clienteId"
        />

      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="PhoneCallIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">
            <router-link :to="{ name: 'negociacao-cliente'}">
              Negociação
            </router-link>
          </span>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BButton } from 'bootstrap-vue'
import ClienteOperacao from '@/views/backoffice/clientes/cards/ClienteOperacao.vue'
import ClienteEventos from '@/views/backoffice/clientes/cards/ClienteEventos.vue'
import ClienteTelefones from '@/views/backoffice/clientes/cards/ClienteTelefones.vue'
import axios from '@/../axios-auth'
import ClienteEmail from './cards/ClienteEmail.vue'
import ClienteGeral from './cards/ClienteGeral.vue'
// import { util } from '../../../libs/util'

export default {
  components: {
    ClienteTelefones,
    ClienteEventos,
    ClienteOperacao,
    ClienteEmail,
    BButton,
    BTabs,
    BTab,
    ClienteGeral,
  },
  // mixins: [util],
  data() {
    return {
      loadCliente: false,
      operacoes: null,
      clienteId: null,
      emails: [
        {
          id: 1,
          email: 'jairo@grupovoz.com.br',
        },
      ],
      eventos: null,
      telefones: null,
      cliente: {
        id: null,
        nome: null,
        bairro: null,
        cep: null,
        cpfCnpj: null,
        rua: null,
        cidade: null,
        uf: null,
        numero: null,
        cidades_id: null,
      },
      options: {
        general: {
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
          username: 'johndoe',
          fullName: 'John Doe',
          email: 'granger007@hogward.com',
          company: 'Crystal Technologies',
        },
        info: {
          bio: '',
          dob: null,
          country: 'USA',
          website: '',
          phone: 6562542568,
        },
        social: {
          socialLinks: {
            twitter: 'https://www.twitter.com',
            facebook: '',
            google: '',
            linkedIn: 'https://www.linkedin.com',
            instagram: '',
            quora: '',
          },
          connections: {
            twitter: {
              // eslint-disable-next-line global-require
              profileImg: require('@/assets/images/avatars/11-small.png'),
              id: 'johndoe',
            },
            google: {
              // eslint-disable-next-line global-require
              profileImg: require('@/assets/images/avatars/3-small.png'),
              id: 'luraweber',
            },
            facebook: {},
            github: {},
          },
        },
        notification: {
          commentOnArticle: true,
          AnswerOnForm: true,
          followMe: false,
          newAnnouncements: true,
          productUpdates: true,
          blogDigest: false,
        },
      },
    }
  },
  beforeCreate() {
    axios.get(`api/v1/clientes/edit/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.cliente.nome = res.data.dados.cliente.nome
        this.clienteId = res.data.dados.cliente.id
        this.cliente.id = res.data.dados.cliente.id
        this.cliente.cpfCnpj = res.data.dados.cliente.cpf_cnpj
        this.cliente.bairro = res.data.dados.cliente.bairro
        this.cliente.cep = res.data.dados.cliente.cep
        this.cliente.rua = res.data.dados.cliente.endereco
        this.cliente.cidade = res.data.dados.cliente.cidades.nome
        this.cliente.uf = res.data.dados.cliente.cidades.uf
        this.cliente.numero = res.data.dados.cliente.numero
        this.cliente.cidades_id = res.data.dados.cliente.cidades_id
        this.loadCliente = true
        // Listagem de Eventos
        // this.eventos = res.data.dados.cliente.eventos
      })
    axios.get(`api/v1/clientes/emails/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        const Emails = []
        const ObjetoEmail = res.data.dados.emails
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const prop in ObjetoEmail) {
          Emails.unshift({
            id: ObjetoEmail[prop].id,
            email: ObjetoEmail[prop].email,
            cliente_id: ObjetoEmail[prop].cliente_id,
            edit: false,
          })
        }
        this.emails = Emails
      })
    axios.get(`api/v1/clientes/telefones/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        const Telefones = []
        const ObjetoTelefone = res.data.dados.telefones
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const prop in ObjetoTelefone) {
          Telefones.push({
            id: ObjetoTelefone[prop].id,
            telefone: ObjetoTelefone[prop].telefone,
            cliente_id: ObjetoTelefone[prop].cliente_id,
            origem: ObjetoTelefone[prop].origem,
            status: 2,
            tags: ObjetoTelefone[prop].tags,
            dataCadastro: this.dataHora(ObjetoTelefone[prop].created_at, true),
            edit: false,
          })
        }
        this.telefones = Telefones
      })
    axios.get(`api/v1/clientes/operacoes/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.operacoes = res.data.dados
      })
  },
}
</script>
