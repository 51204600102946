<template>
  <div>
    <b-button
      v-if="permissions['clientes.store'].permissao"
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-prevent-closing
      class="mb-1"
      variant="outline-primary"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Novo Telefone</span>
    </b-button>
    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Cadastrar telefone"
      cancel-title="Cancelar"
      ok-title="Salvar"
      cancel-variant="outline-secondary"
      @show="zeroCampos"
      @hidden="zeroCampos"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="Telefone"
          label-for="telefone"
        >
          <cleave
            id="telefone"
            v-model="telefone"
            class="form-control"
            :raw="false"
            :options="mask.telefone"
            placeholder="(99) 99999-9999"
          />
        </b-form-group>
        <b-form-group
          label="Origem"
          label-for="Informe a origem"
        >
          <v-select
            v-model="selectedOrigem"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="optionOrigem"
          />
        </b-form-group>
        <b-form-group
          label="Tags"
          label-for="Informe as tags"
        >
          <v-select
            v-model="selectedTags"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="optionTags"
          />
        </b-form-group>
        <b-form-group
          label="Confirmado"
          label-for="confirmado"
        >

          <b-form-checkbox
            v-model="check"
            :checked="check"
            class="custom-control-success"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>

        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="modal-prevent-closing2"
      ref="my-modal"
      title="Atualizar Telefone"
      ok-title="Atualizar"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      @hidden="zeroCampos"
      @ok="handleOk2"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="Telefone"
          label-for="telefone"
        >
          <cleave
            id="telefone"
            v-model="telefone"
            class="form-control"
            :raw="false"
            :options="mask.telefone"
            placeholder="(99) 99999-9999"
          />
        </b-form-group>
        <b-form-group
          label="Origem"
          label-for="Informe a origem"
        >
          <v-select
            v-model="selectedOrigem"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="optionOrigem"
          />
        </b-form-group>
        <b-form-group
          label="Tags"
          label-for="Informe as tags"
        >
          <v-select
            v-model="selectedTags"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="optionTags"
          />
        </b-form-group>
        <b-form-group
          label="Confirmado"
          label-for="confirmado"
        >

          <b-form-checkbox
            v-model="check"
            :checked="check"
            class="custom-control-success"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>

        </b-form-group>
      </form>
    </b-modal>
    <b-card>
      <b-col cols="12">
        <b-table
          striped
          responsive
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                v-if="permissions['clientes.update'].permissao"
                class="color-icon"
                icon="EditIcon"
                size="16"
                @click="modalAtualizar(data.item)"
              />
              <feather-icon
                v-if="permissions['clientes.delete'].permissao"
                icon="TrashIcon"
                size="16"
                class="mx-1 trash-icon"
                @click="deleteTelefone(data.item.id, data.item.telefone)"
              />
            </div>
          </template>
        </b-table>
      </b-col>
    </b-card>
  </div>

</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BCard,
  BCol,
  BButton,
  BModal,
  BFormGroup,
  BFormCheckbox,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { util } from '../../../../libs/util'
import Cleave from 'vue-cleave-component'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BCard,
    BCol,
    BButton,
    BModal,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  // mixins: [util],
  props: {
    telefones: {
      type: Array,
      default: () => {},
    },
    clienteId: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      permissions: null,
      confirmado: null,
      idTelefone: null,
      telefone: null,
      check: false,
      selectedTags: null,
      optionTags: [{ title: 'Móvel' }, { title: 'Móvel/Whatsapp' }, { title: 'Fixo' }],
      selectedOrigem: null,
      optionOrigem: [{ title: 'Credor' }, { title: 'Enriquecimento' }, { title: 'Atualização Cadastral' }],
      pageOptions: [3, 5, 10],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'telefone', label: 'Telefone', sortable: true },
        { key: 'origem', label: 'Origem', sortable: true },
        { key: 'status', label: 'Confirmado', sortable: true },
        { key: 'tags', label: 'Tag', sortable: true },
        { key: 'dataCadastro', label: 'Cadastro' },
        { key: 'actions', label: 'ações' },
      ],
      items: null,
      status: [{
        1: 'Sim', 2: 'Não', 3: 'Rejected',
      },
      {
        1: 'light-success', 2: 'light-warning',
      }],
      mask: {
        telefone: {
          delimiters: ['(', ')', ' ', '-', '-'],
          blocks: [0, 2, 0, 5, 4],
          uppercase: true,
        },
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.permissoes).clientes
  },
  mounted() {
    this.items = this.telefones
    // Set the initial number of items
  },
  methods: {
    zeroCampos() {
      this.idTelefone = null
      this.telefone = null
      this.selectedOrigem = null
      this.selectedTags = null
      this.check = false
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleOk2(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.atualizarTelefone()
    },
    handleSubmit() {
      const formData = {
        telefone: this.telefone.replace(/[^0-9]/g, ''),
        origem: this.selectedOrigem.title,
        tags: this.selectedTags.title,
        status: this.check,
        cliente_id: this.clienteId,
      }
      axios.post('api/v1/clientes/telefones/store', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.atualizarLista()
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
          // this.updateListTelefone()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.mensagem,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
          this.zeroCampos()
        })
        .catch(error => {
          this.$swal({
            title: 'Erro ao cadastrar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    atualizarTelefone() {
      const formData = {
        telefone: this.telefone.replace(/[^0-9]/g, ''),
        origem: this.selectedOrigem.title,
        tags: this.selectedTags.title,
        cliente_id: this.clienteId,
        status: this.check,
      }
      // eslint-disable-next-line vue/no-mutating-props
      axios.put(`api/v1/clientes/telefones/update/${this.idTelefone}`, formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.atualizarLista()
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.mensagem,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
          this.zeroCampos()
        })
        .catch(error => {
          this.btNovoTelefone = true
          this.novoTelefone = ''
          this.$swal({
            title: 'Erro ao atualizar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    atualizarLista() {
      axios.get(`api/v1/clientes/telefones/${this.clienteId}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          const Telefones = []
          const ObjetoTelefone = res.data.dados.telefones
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const prop in ObjetoTelefone) {
            if (ObjetoTelefone[prop].status) {
              this.confirmado = 1
            } else {
              this.confirmado = 2
            }
            Telefones.push({
              id: ObjetoTelefone[prop].id,
              telefone: ObjetoTelefone[prop].telefone,
              cliente_id: ObjetoTelefone[prop].cliente_id,
              origem: ObjetoTelefone[prop].origem,
              status: this.confirmado,
              tags: ObjetoTelefone[prop].tags,
              dataCadastro: this.dataHora(ObjetoTelefone[prop].created_at, true),
              edit: false,
            })
          }
          // eslint-disable-next-line vue/no-mutating-props
          this.items = Telefones
        })
    },
    deleteTelefone(id, numero) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o numero ${numero}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim quero deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/clientes/telefones/delete/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data) {
                this.atualizarLista()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Numero deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    modalAtualizar(item) {
      this.$bvModal.show('modal-prevent-closing2')
      this.idTelefone = item.id
      this.telefone = item.telefone
      this.selectedOrigem = [{ title: item.origem }]
      this.selectedTags = [{ title: item.tags }]
      // (data.item.id, data.item.telefone, data.item.origem, data.item.tags)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
