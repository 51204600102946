<template>
  <b-card title="Emails">
    <div>
      <b-row
        v-for="(email, index) in emailData"
        :id="email.id"
        :key="email.id"
        ref="row"
      >
        <b-col
          sm="7"
          class="mb-1"
        >
          <b-input-group v-if="!email.edit">
            <b-form-input
              v-model="email.email"
              name="email"
              readonly
            />
            <b-input-group-append v-if="permissions['clientes.update'].permissao">
              <b-button
                variant="outline-primary"
                @click="editarEmail(index)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-input-group-append>
            <b-input-group-append v-if="permissions['clientes.delete'].permissao">
              <b-button
                variant="outline-danger"
                @click="excluirEmail(email.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-input-group v-else>
            <b-form-input
              v-model="email.email"
              name="email"
              placeholder="Informe o email"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="atualizarEmail(email.id, email.email)"
              >
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="5">
            <b-form-group>
              <b-form-input
                id="novo-email"
                v-model="novoEmail"
                type="email"
                placeholder="Informe o novo email"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="3"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-primary"
              class="mt-md-0"
              @click="salvarEmail(index)"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-25"
              />
              <span>Salvar</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <div v-if="permissions['clientes.store'].permissao">
      <b-button
        v-show="btNovoEmail"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Novo Email</span>
      </b-button>
    </div>

  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    emailData: {
      type: Array,
      default: () => {},
    },
    clienteId: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      permissions: null,
      btNovoEmail: true,
      novoEmail: null,
      edit: false,
      Emails: null,
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.permissions = JSON.parse(localStorage.permissoes).clientes
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    editarEmail(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.emailData[index].edit = true
    },
    atualizarEmail(id, email) {
      const formData = {
        email,
        cliente_id: this.clienteId,
      }
      axios.put(`api/v1/clientes/emails/update/${id}`, formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.updateListEmail()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.mensagem,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.btNovoEmail = true
          this.novoEmail = ''
          this.$swal({
            title: 'Erro ao cadastrar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    repeateAgain() {
      this.btNovoEmail = false
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    salvarEmail(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      const formData = {
        email: this.novoEmail,
        cliente_id: this.clienteId,
      }
      axios.post('api/v1/clientes/emails/store', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.updateListEmail()
          this.btNovoEmail = true
          this.novoEmail = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.mensagem,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.btNovoEmail = true
          this.novoEmail = ''
          this.$swal({
            title: 'Erro ao cadastrar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    updateListEmail() {
      axios.get(`api/v1/clientes/emails/${this.clienteId}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          const Emails = []
          const ObjetoEmail = res.data.dados.emails
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const prop in ObjetoEmail) {
            Emails.unshift({
              id: ObjetoEmail[prop].id,
              email: ObjetoEmail[prop].email,
              cliente_id: ObjetoEmail[prop].cliente_id,
              edit: false,
            })
          }
          // eslint-disable-next-line vue/no-mutating-props
          this.emailData = Emails
        })
    },
    excluirEmail(id) {
      axios.delete(`api/v1/clientes/emails/delete/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.updateListEmail()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.mensagem,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
