<template>
  <div>
    <b-row
      class="mb-2"
      align-h="end"
    >
      <b-col cols="2">
        <b-button
          variant="primary"
          @click="$refs.modalOperacaoAvulsa.show()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          Operação
        </b-button>
      </b-col>
    </b-row>
    <b-card title="Operações">
      <b-row
        class="mb-2"
        align-h="end"
      >
        <b-col
          lg="8"
          class="d-flex justify-content-end"
        >
          <b-form-input
            v-model="filter"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        small
        :fields="fields"
        :items="items"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        responsive="sm"
        class="text-center"
        @row-clicked="rowClick"
      >
        <template #cell(status_nome)="data">
          <b-badge
            pill
            :style="{background: data.item.color, color: '#fff',}"
          >
            {{ data.item.sigla }}
          </b-badge>
        </template>
        <template #cell(numero_acordo)="data">
          <b-link :to="{name: 'parcelas-acordo', params: {id: data.item.acordo_id }}">
            {{ data.item.numero_acordo }}
          </b-link>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        size="sm"
        align="center"
      />
    </b-card>
    <b-modal
      id="modal"
      ref="modal"
      hide-footer
      centered
      size="lg"
      title="Detalhes da Operação"
    >
      <InfoCard
        :id-op="id"
      />
    </b-modal>
    <b-modal
      ref="modalOperacaoAvulsa"
      title="Adicionar Operação Avulsa"
      no-close-on-backdrop
      ok-title="Salvar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @ok="storeOperacaoAvulsa"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          Credor:
          <v-select
            v-model="creditorForm"
            :options="creditors"
            :close-on-select="false"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Aluno:
          <b-form-input
            v-model="nameAluno"
            type="text"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Número da Operação:
          <b-form-input
            v-model="operationNumber"
            type="text"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Descrição:
          <b-form-input
            v-model="descricaoForm"
            type="text"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Competência:
          <b-form-input
            v-model="competencia"
            type="text"
            required
          />
        </b-col>
        <b-col
          cols="8"
          class="mb-2"
        >
          Vencimento:
          <b-input-group class="mb-2">
            <the-mask
              v-model="formVencimento.data_vencimento_form"
              autocomplete="off"
              :mask="['##/##/####']"
              :masked="true"
              class="form-control"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="formVencimento.data_vencimento_form_en"
                show-decade-nav
                button-only
                right
                size="sm"
                locale="en-US"
                aria-controls="example-input"
                @input="changeDataFormat($event, 'data_vencimento_form')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          Valor Nominal:
          <b-form-input
            v-model="nominalValue"
            type="number"
            required
          />
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          Desconto:
          <b-form-input
            v-model="desconto"
            type="number"
            required
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>

</template>

<script>
import {
  BTable, BCard, BLink, BBadge, BPagination, BFormInput, BCol, BRow, BModal, BButton, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { TheMask } from 'vue-the-mask'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'
import InfoCard from '@/views/backoffice/operacao/cards/OperacoesInfo.vue'

export default {
  components: {
    InfoCard,
    BCol,
    BRow,
    BTable,
    BCard,
    BLink,
    BBadge,
    BPagination,
    BFormInput,
    BModal,
    BButton,
    BFormDatepicker,
    TheMask,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  props: {
    operacoes: {
      type: Array,
      default: () => {
      },
    },
    info: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      filter: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      items: [],
      status_operacoes: {},
      creditors: [],
      creditorForm: [],
      fields: [
        { key: 'status_nome', label: 'status' },
        { key: 'numero_operacao', label: 'operação' },
        // { key: 'numero_acordo', label: 'acordo' },
        { key: 'data_vencimento', label: 'vencimento' },
        // A virtual column made up from two fields
        { key: 'valor_nominal', label: 'valor nominal', class: 'text-right' },
      ],
      operationNumber: '',
      formVencimento: {
        data_vencimento_form: '27/06/2022',
        data_vencimento_form_en: '2022-06-27',
      },
      nominalValue: null,
      status_operacoes_form: [],
      descricaoForm: '',
      nameAluno: '',
      desconto: '',
      competencia: '',
      clienteId: '',
    }
  },
  watch: {
    'formVencimento.data_vencimento_form': {
      handler(n) {
        if (n.length === 10) {
          const splited = n.split('/')
          const newDate = `${splited[2]}-${splited[1]}-${splited[0]}`
          if (this.formVencimento.data_vencimento_form_en !== newDate) {
            this.formVencimento.data_vencimento_form_en = newDate
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.getStatusOperacao()
    this.getCredores()
  },

  methods: {
    rowClick(item) {
      this.id = null
      this.id = item.id
      this.$bvModal.show('modal')
    },
    clearModal() {
      this.creditorForm = ''
      this.nameAluno = ''
      this.operationNumber = ''
      this.descricaoForm = ''
      this.competencia = ''
      this.nominalValue = ''
      this.formVencimento.data_vencimento_form_en = ''
    },
    async getOperacoes() {
      this.items = []
      // eslint-disable-next-line array-callback-return
      await this.operacoes.map(item => {
        const { sigla } = this.status_operacoes[item.status_operacao.sigla]
        const { color } = this.status_operacoes[item.status_operacao.sigla]
        // eslint-disable-next-line default-case
        this.items.push({
          id: item.id,
          cliente_id: item.cliente_id,
          sigla,
          color,
          numero_operacao: item.numero_operacao,
          data_vencimento: this.dataHora(item.data_vencimento, true),
          valor_nominal: this.valorBr(item.valor_nominal, true),
        })
        this.totalRows = this.items.length
      })
    },
    async getStatusOperacao() {
      await axios.get('api/v1/info/statusoperacao', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        this.status_operacoes = {}
        const colors = {
          C: '#B22222',
          L: '#66CDAA',
          D: '#696969',
          N: '#836FFF',
        }
        // eslint-disable-next-line array-callback-return
        res.data.dados.map(dt => {
          this.status_operacoes[dt.sigla] = {
            sigla: dt.sigla,
            color: colors[dt.sigla],
          }
        })
        res.data.dados.map(item => {
          this.status_operacoes_form.push({ id: item.id })
        })
      })
      this.getOperacoes()
    },
    async getCredores() {
      await axios.get('api/v1/credores/listar/false', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.creditors.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    changeDataFormat(item, pos) {
      const splited = item.split('-')
      const newDate = `${splited[2]}/${splited[1]}/${splited[0]}`
      if (!this.formVencimento[pos] !== newDate) {
        this.formVencimento[pos] = newDate
      }
    },

    async storeOperacaoAvulsa() {
      // eslint-disable-next-line camelcase
      const { data_vencimento_form_en: data_vencimento } = this.formVencimento

      // const credorID = []
      // this.creditorForm.id(credor => {
      //   credorID.push(credor.id)
      // })

      const statusId = this.status_operacoes_form[0].id

      const clientID = []
      this.items.map(clienteid => {
        clientID.push(clienteid.cliente_id)
      })
      const body = {
        credor_id: this.creditorForm.id,
        aluno: this.nameAluno,
        numero_operacao: this.operationNumber,
        data_vencimento,
        descricao: this.descricaoForm,
        competencia: this.competencia,
        valor_nominal: this.nominalValue,
        status_operacao_id: statusId,
        cliente_id: this.info,
        valor_desconto: this.desconto,
      }
      if (body.credor_id.length === 0
          || body.aluno.length === ''
          || body.numero_operacao.length === ''
          || body.data_vencimento === ''
          || body.descricao.length === ''
          || body.competencia.length === ''
          || body.valor_nominal.length === ''
      ) {
        this.$swal({
          icon: 'error',
          title: 'Erro na seleção',
          text: 'Verifique se os seguintes campos foram preenchidos: Credor, Aluno, Número da Operação, Data de Vencimento, Descrição, Competência e Valor Nominal',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        axios.post('api/v1/remessas/remessa_avulsa_form', body, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            accept: 'application/json',
          },
        }).then(async () => {
          this.$router.go()
          this.clearModal()
          await this.getStatusOperacao()
          await this.$swal({
            title: 'Operação adicionada',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
      }
    },
  },

}
</script>
